import {
  email,
  helpers,
  required,
  requiredIf,
  minLength,
  maxLength,
  minValue,
  sameAs,
} from '@vuelidate/validators/dist/raw.mjs';

import dayjs from './dayjs';
import { authStage } from './auth';
import { profileStage } from './profile';

const cyrrylicAndDash = helpers.regex(/[а-яА-Я-]{2,}/);
const date = helpers.regex(/\d{2}.\d{2}.\d{4}/);
const integer = helpers.regex(/^[1-9]\d*/);
const fio = helpers.regex(/[а-яА-Я -]{2,}/);
const phone = helpers.regex(/\+7 \(9\d{2}\) \d{3} \d{2}-\d{2}/);
const newPasswordValidate = helpers.regex(
  /(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!@#$%^&*]{6,}/,
);
const fourDigitNumber = helpers.regex(/^[0-9]{4}/);

const pastDate = (nv) => {
  const pastDate = dayjs(nv, 'DD.MM.YYYY', true).format();
  if (pastDate === 'Invalid Date') {
    return false;
  }
  const diff1 = dayjs().diff(pastDate, 'minute');
  if (diff1 < 0) {
    return false;
  }
  const diff2 = dayjs().diff(pastDate, 'year');
  if (!nv || isNaN(diff2) || diff2 >= 100) {
    return false;
  }

  return true;
};

const birthDate = (nv) => {
  const birthDate = dayjs(nv, 'DD.MM.YYYY', true).format();
  if (birthDate === 'Invalid Date') {
    return false;
  }
  const diff = dayjs().diff(birthDate, 'year');
  if (!nv || isNaN(diff) || diff < 18 || diff > 72) {
    return false;
  }
  return true;
};

const AuthRules = (authStore) =>
  computed(() => {
    return {
      phoneNumber: { requiredIf: requiredIf(() => authStore.stage !== authStage.SMS_CODE), phone },
      password: {
        requiredIf: requiredIf(
          () =>
            authStore.stage === authStage.WITH_PASSWORD ||
            authStore.stage === authStage.REGISTRATION,
        ),
      },
      confirmPassword: {
        requiredIf: requiredIf(() => authStore.stage === authStage.REGISTRATION),
        sameAsPassword: sameAs(authStore.password),
      },
      smsCode: {
        requiredIf: requiredIf(() => authStore.stage === authStage.SMS_CODE),
        minLength: minLength(4),
        maxLength: maxLength(4),
      },
    };
  });

const ProfileRules = (profileData) =>
  computed(() => {
    return {
      email: {
        requiredIf: requiredIf(() => profileData.stage === profileStage.DEFAULT),
        email,
      },
      phoneNumber: {
        requiredIf: requiredIf(() => profileData.stage === profileStage.CHANGE_BY_OLD_PHONE_NUMBER),
      },
      password: {
        requiredIf: requiredIf(() => profileData.stage === profileStage.SET_PERMANENT_PASSWORD),
      },
      confirmPassword: {
        requiredIf: requiredIf(() => profileData.stage === profileStage.SET_PERMANENT_PASSWORD),
        sameAsPassword: sameAs(profileData.password),
      },
    };
  });

const step1Rules = computed(() => {
  return {
    first_name: {
      required,
      cyrrylicAndDash,
    },
    last_name: {
      required,
      cyrrylicAndDash,
    },
    city: {
      required,
    },
    cityKLADR_residence_kladr_id: {
      required,
      minLength: minLength(10),
    },
    secondary_name: {
      cyrrylicAndDash,
    },
    email: {
      email,
    },
  };
});

const step2Rules = computed(() => {
  return {
    passport_serie: {
      required,
      minLength: minLength(4),
      maxLength: maxLength(4),
    },
    passport_number: {
      required,
      minLength: minLength(6),
      maxLength: maxLength(6),
    },
    passport_issuedby: {
      required,
    },
    passport_date: {
      required,
      pastDate,
    },
    passport_devcode: {
      required,
      minLength: minLength(7),
      maxLength: maxLength(7),
    },
    passport_city: { required },
    birthday: {
      required,
      birthDate,
    },
    birthplace: {
      required,
    },
  };
});

const step3Rules = (dataForLongStore) =>
  computed(() => {
    return {
      adressKLADR_residence_city_with_type: { required },
      adressKLADR_residence_region_with_type: { required },
      adressKLADR_residence_street_with_type: { required },
      adressKLADR_residence_house: { required },
      adressKLADR_registration_city_with_type: {
        requiredIf: requiredIf(() => !dataForLongStore.step_3.lack_of_registration),
      },
      adressKLADR_registration_region_with_type: {
        requiredIf: requiredIf(() => !dataForLongStore.step_3.lack_of_registration),
      },
      adressKLADR_registration_street_with_type: {
        requiredIf: requiredIf(() => !dataForLongStore.step_3.lack_of_registration),
      },
      adressKLADR_registration_house: {
        requiredIf: requiredIf(() => !dataForLongStore.step_3.lack_of_registration),
      },
      adressKLADR_temp_registration_city_with_type: {
        requiredIf: requiredIf(
          () =>
            dataForLongStore.step_3.lack_of_registration ||
            dataForLongStore.step_3.address_difference,
        ),
      },
      adressKLADR_temp_registration_region_with_type: {
        requiredIf: requiredIf(
          () =>
            dataForLongStore.step_3.lack_of_registration ||
            dataForLongStore.step_3.address_difference,
        ),
      },
      adressKLADR_temp_registration_street_with_type: {
        requiredIf: requiredIf(
          () =>
            dataForLongStore.step_3.lack_of_registration ||
            dataForLongStore.step_3.address_difference,
        ),
      },
      adressKLADR_temp_registration_house: {
        requiredIf: requiredIf(
          () =>
            dataForLongStore.step_3.lack_of_registration ||
            dataForLongStore.step_3.address_difference,
        ),
      },
    };
  });

const step4Rules = (dataForLongStore) =>
  computed(() => {
    return {
      work_mode: { required, minValue: minValue(0) },
      marital_status: { required, minValue: minValue(0) },
      fixed_income: {
        requiredIf: requiredIf(() => Number(dataForLongStore.step_4.work_mode) === 1),
      },
      official_part_of_income: {
        requiredIf: requiredIf(
          () =>
            Number(dataForLongStore.step_4.work_mode) === 1 ||
            Number(dataForLongStore.step_4.work_mode) === 2,
        ),
      },
      additional_income_comment: {
        requiredIf: requiredIf(() => dataForLongStore.step_4.additional_income),
      },
      work_type_guid: {
        requiredIf: requiredIf(() => Number(dataForLongStore.step_4.work_mode) === 1),
      },
      work_position: {
        requiredIf: requiredIf(() => Number(dataForLongStore.step_4.work_mode) === 1),
      },
      avg_monthly_payment: {
        required,
        minValue: minValue(0),
      },
      spouse_full_name: {
        requiredIf: requiredIf(() => Number(dataForLongStore.step_4.marital_status) === 1),
        fio,
      },
      work_company: {
        requiredIf: requiredIf(() => Number(dataForLongStore.step_4.work_mode) === 1),
      },
      work_company_phone: {
        requiredIf: requiredIf(() => Number(dataForLongStore.step_4.work_mode) === 1),
      },
      adressKLADR_work_city_with_type: {
        requiredIf: requiredIf(() => Number(dataForLongStore.step_4.work_mode) === 1),
      },
      adressKLADR_work_region_with_type: {
        requiredIf: requiredIf(() => Number(dataForLongStore.step_4.work_mode) === 1),
      },
      adressKLADR_work_street_with_type: {
        requiredIf: requiredIf(() => Number(dataForLongStore.step_4.work_mode) === 1),
      },
      adressKLADR_work_house: {
        requiredIf: requiredIf(() => Number(dataForLongStore.step_4.work_mode) === 1),
      },
      contact_persons_1_full_name: { required, fio },
      contact_persons_1_kind_text: { required, fio },
      contact_persons_1_mobile_phone: { required, phone },
      contact_persons_2_full_name: { fio },
      contact_persons_2_kind_text: { fio },
      contact_persons_2_mobile_phone: { phone },
    };
  });

const AppealRules = computed(() => {
  return {
    fullName: { required, cyrrylicAndDash },
    phone: { required, phone },
    email: { required, email },
    message: { required },
  };
});

export {
  AuthRules,
  ProfileRules,
  fio,
  phone,
  date,
  integer,
  fourDigitNumber,
  newPasswordValidate,
  step1Rules,
  step2Rules,
  step3Rules,
  step4Rules,
  AppealRules,
};
